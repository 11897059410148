import React from "react"

import { useCustomerRegister, useShowPassword } from "../../../hooks/useCustomer"

export const withRegisterForm = Component => ({ name = "RegisterForm", ...props }) => {
  const { createCustomer, data, setData, loading, errors } = useCustomerRegister()
  const { showPassword, setShowPassword } = useShowPassword()

  const handleSubmit = async event => {
    event.preventDefault()
    await createCustomer({
      ...data,
    })
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const handleShowPassword = () => {
    setShowPassword(prevState => !prevState)
  }

  const handleCheckboxChange = checkedValue => {
    const event = {
      target: {
        name: "acceptsMarketing",
        type: "checkbox",
        checked: !checkedValue,
      },
    }

    handleChange(event)
  }

  const isInvalid =
    data.password === "" || data.password !== data.confirmedPassword || data.email === "" || data.firstName === "" || data.lastName === ""

  Component.displayName = name

  return (
    <Component
      {...props}
      data={data}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      errors={errors}
      showPassword={showPassword}
      handleShowPassword={handleShowPassword}
      handleCheckboxChange={handleCheckboxChange}
      isInvalid={isInvalid}
    />
  )
}
