import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withRegisterForm } from "./withRegisterForm"
import { StyledButton } from "../../Styled/Button"
import FormInput from "../../Form/FormInput"
import Icon from "../../Icon"

const Input = styled(FormInput)`
  ${tw`mb-2-4 px-0`}
`

const InputWrapper = tw.div`
  relative
`

const StyledIcon = styled(Icon)`
  ${tw`absolute right-0 top-0-6`}
`

const Submit = styled(StyledButton)`
  ${tw`w-full`}
`

const Addtional = tw.div`
  flex flex-row justify-between mb-6-4 md:mb-3-2
`

const SubscribeWrapper = tw.div`
  flex flex-row items-start md:items-center cursor-pointer
`

const Subscribe = tw.span`
  inline-block text-12 leading-1.67 ml-0-8 -mt-0-2 md:mt-0
`

const Error = tw.div`
  text-16 mb-2-4 text-orange
`

export const RegisterForm = withRegisterForm(
  ({ data, handleChange, handleSubmit, loading, errors, className, showPassword, handleShowPassword, handleCheckboxChange, isInvalid }) => {
    return (
      <div className={className}>
        <form onSubmit={handleSubmit}>
          <Input
            name={`firstName`}
            placeholder={`First name`}
            placeholderColor={`dark`}
            value={data.firstName}
            handleChange={handleChange}
            required
          />
          <Input name={`lastName`} placeholder={`Last name`} placeholderColor={`dark`} value={data.lastName} handleChange={handleChange} required />
          <Input
            name={`email`}
            type={`email`}
            placeholder={`Email`}
            placeholderColor={`dark`}
            value={data.email}
            handleChange={handleChange}
            required
          />
          <InputWrapper>
            <Input
              name={`password`}
              type={showPassword ? `text` : `password`}
              placeholder={`Password`}
              placeholderColor={`dark`}
              value={data.password}
              handleChange={handleChange}
              required
            />
            <StyledIcon name={!showPassword ? `show` : `hide`} size={`xsmall`} onClick={handleShowPassword} />
          </InputWrapper>
          <Input
            name={`confirmedPassword`}
            type={`password`}
            placeholder={`Confirm password`}
            placeholderColor={`dark`}
            value={data.confirmedPassword}
            handleChange={handleChange}
            required
          />
          {errors?.length > 0 &&
            errors?.filter(error => error?.field?.length && error?.field[1] === "password").map(error => <Error>{error?.message}</Error>)}
          <Addtional>
            <SubscribeWrapper onClick={() => handleCheckboxChange(data.acceptsMarketing)}>
              <Icon name={!data.acceptsMarketing ? `tickedBoxInactive` : `tickedBoxActive`} size={`xxs`} />
              <Subscribe>Sign up for early access to new collections and special promotions.</Subscribe>
            </SubscribeWrapper>
          </Addtional>
          <Submit type={`submit`} height={`56`} textSize={`16`} colour={`green`} disabled={loading || isInvalid}>
            Register
          </Submit>
        </form>
      </div>
    )
  }
)

RegisterForm.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    confirmedPassword: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    acceptsMarketing: PropTypes.bool,
  }),
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  errors: PropTypes.any,
  className: PropTypes.string,
  showPassword: PropTypes.bool,
  handleShowPassword: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  isInvalid: PropTypes.bool,
}
