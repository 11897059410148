import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { StyledButton } from "../Styled/Button"
import Icon from "../Icon"

const Wrapper = tw.div`
  flex flex-col lg:flex-row lg:-mx-1
`

const Button = styled(StyledButton)`
  ${tw`w-full lg:mx-1 lg:mb-0`}
`

//TODO social login

const AccountSocials = ({ className, buttonText }) => {
  return (
    <div className={className}>
      <Wrapper>
        <Button height={`56`} textSize={`16`} border className={`mb-1-6`}>
          <Icon name={`facebook`} size={`sm`} />
          <span>{`${buttonText} with Facebook`}</span>
        </Button>
        <Button height={`56`} textSize={`16`} border>
          <Icon name={`google`} size={`sm`} />
          <span>{`${buttonText} with Google`}</span>
        </Button>
      </Wrapper>
    </div>
  )
}

AccountSocials.propTypes = {
  className: PropTypes.string,
  buttonText: PropTypes.string,
}

export default AccountSocials
