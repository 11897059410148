import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { StyledInput } from "../Styled/FormElements"

const Wrapper = styled.div`
  ${tw`px-1-6 w-full`}
  ${({ width }) => (width === `1/2` ? tw`md:w-1/2` : null)}
`

const Input = styled(StyledInput)`
  ::placeholder {
    ${({ placeholderColor }) => (placeholderColor === `dark` ? tw`text-dark` : tw`text-grey`)}
  }
`

const FormInput = ({
  type,
  width,
  name,
  placeholder,
  value,
  handleChange,
  placeholderColor,
  required,
  className,
}) => {
  return (
    <Wrapper width={width} className={className}>
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        required={required}
        placeholderColor={placeholderColor}
      />
    </Wrapper>
  )
}

FormInput.propTypes = {
  type: PropTypes.string,
  width: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  placeholderColor: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
}

export default FormInput
