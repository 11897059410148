import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { withRegister } from "./withRegister"
import { BackgroundContainer, StyledContainer } from "../../Styled/Container"
import ImageContent from "../../ImageContent"
import AccountNav from "../AccountNav"
import { RegisterForm } from "./RegisterForm"
import AccountSocials from "../AccountSocials"
import { GatsbyImageProps } from "../../../props/ImageProps"
import Breadcrumb from "../../Breadcrumb/Breadcrumb"

const Container = styled(StyledContainer)`
  ${tw`relative max-w-xl mx-auto bg-light md:bg-lighter`}
`

const Content = tw.div`
  max-w-59-2 md:max-w-72 mx-auto px-2-4 md:pl-4-8 md:pr-7-2 lg:pl-9-6 lg:pr-10-4 h-full flex justify-center flex-col pt-8-8 md:pt-0
`

const Nav = styled(AccountNav)`
  ${tw`mb-4-8 md:mb-2-4`}
`

const Form = styled(RegisterForm)`
  ${tw`mb-1-6 md:mb-2-4`}
`

const Socials = styled(AccountSocials)`
  ${tw`mb-2-4 md:mb-3-2`}
`

const Terms = tw.div`
  max-w-25 mx-auto sm:max-w-full w-full text-12 leading-1.67 opacity-75 text-center mb-5-6 md:mb-3-2 opacity-60
`

const TermsLink = styled(Link)`
  ${tw`underline`}
`

export const Register = withRegister(({ title, image, terms, privacy, paths }) => {
  return (
    <BackgroundContainer background={`light`}>
      <Container width={"full"}>
        {paths && <Breadcrumb paths={paths} />}
        <ImageContent image={image} mobileHidden>
          <Content>
            <Nav />
            <Form />
            <Socials buttonText={title} />
            <Terms>
              By creating an account, you agree to our&nbsp;
              <TermsLink to={terms?.link}>{terms?.title}</TermsLink>
              &nbsp;and&nbsp;
              <TermsLink to={privacy?.link}>{privacy?.title}</TermsLink>.
            </Terms>
          </Content>
        </ImageContent>
      </Container>
    </BackgroundContainer>
  )
})

Register.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape(GatsbyImageProps),
  terms: PropTypes.shape({
    link: PropTypes.string,
    title: PropTypes.string,
  }),
  privacy: PropTypes.shape({
    link: PropTypes.string,
    title: PropTypes.string,
  }),
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}
