import React from "react"

export const withRegister = Component => ({ name = "Register", page, ...props }) => {
  const title = page?.title
  const image = page?.image?.asset?.fluid
  const terms = page?.terms
  const privacy = page?.privacy

  const paths = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Register",
    },
  ]

  Component.displayName = name
  return <Component {...props} title={title} image={image} paths={paths} terms={terms} privacy={privacy} />
}
