import React from "react"
import { graphql } from "gatsby"

import { Register as Page } from "../../components/Account/Register/Register"

export const query = graphql`
  query {
    page: sanityPageAccountRegister {
      title
      terms {
        link
        title
      }
      privacy {
        link
        title
      }
      image {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }

      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
