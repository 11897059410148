import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { navigate } from "gatsby"

import { isBrowser } from "../../utils/domUtils"

const NavItem = styled.div`
  ${tw`inline-block text-38 leading-1.21 md:text-44 md:leading-1.45 font-display text-grey`}
  ${({ active }) => (active ? tw`text-dark` : tw`cursor-pointer`)}
  ${({ divider }) => (divider ? tw`mx-1-6` : null)}
`

const AccountNav = ({ className }) => {
  return (
    <div className={className}>
      {isBrowser && (
        <>
          <NavItem active={window.location.pathname.includes(`/account/login`)} onClick={() => navigate("/account/login")}>
            Login
          </NavItem>
          <NavItem divider>|</NavItem>
          <NavItem active={window.location.pathname.includes(`/account/register`)} onClick={() => navigate("/account/register")}>
            Register
          </NavItem>
        </>
      )}
    </div>
  )
}

AccountNav.propTypes = {
  className: PropTypes.string,
}

export default AccountNav
